<template functional>
  <div class="official-source">
    <h2 class="official-source__title">
      Используем только официальные источники данных
    </h2>
    <div class="official-source_box">
      <div class="official-source_box_item" itemscope itemtype="http://schema.org/ImageObject">
        <img
          v-lazy-load
          src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
          :data-src="require('~/assets/img/source/gibdd.svg')"
          alt="База данных ГИБДД"
          class="official-source_box_item__image"
          loading="lazy"
          itemprop="contentUrl"
        >
        <div class="official-source_box_item_text-block">
          <div class="official-source_box_item_text-block__title" itemprop="name">
            База данных ГИБДД
          </div>
        </div>
      </div>
      <div class="official-source_box_item" itemscope itemtype="http://schema.org/ImageObject">
        <img
          v-lazy-load
          src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
          :data-src="require('~/assets/img/source/fkr.svg')"
          alt="Федеральное Казначейство России"
          class="official-source_box_item__image"
          loading="lazy"
          itemprop="contentUrl"
        >
        <div class="official-source_box_item_text-block">
          <div class="official-source_box_item_text-block__title" itemprop="name">
            Федеральное Казначейство России
          </div>
        </div>
      </div>
      <div class="official-source_box_item" itemscope itemtype="http://schema.org/ImageObject">
        <img
          v-lazy-load
          src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
          :data-src="require('~/assets/img/source/fssp.svg')"
          alt="Банк данных ФССП"
          class="official-source_box_item__image"
          loading="lazy"
          itemprop="contentUrl"
        >
        <div class="official-source_box_item_text-block">
          <div class="official-source_box_item_text-block__title" itemprop="name">
            Банк данных ФССП
          </div>
        </div>
      </div>
      <div class="official-source_box_item" itemscope itemtype="http://schema.org/ImageObject">
        <img
          v-lazy-load
          src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
          :data-src="require('~/assets/img/source/madi.svg')"
          alt="МАДИ, АМПП"
          class="official-source_box_item__image"
          loading="lazy"
          itemprop="contentUrl"
        >
        <div class="official-source_box_item_text-block">
          <div class="official-source_box_item_text-block__title" itemprop="name">
            МАДИ, АМПП
          </div>
        </div>
      </div>
      <div class="official-source_box_item" itemscope itemtype="http://schema.org/ImageObject">
        <img
          v-lazy-load
          src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
          :data-src="require('~/assets/img/source/mugadn.svg')"
          alt="МУГАДН"
          class="official-source_box_item__image"
          loading="lazy"
          itemprop="contentUrl"
        >
        <div class="official-source_box_item_text-block">
          <div class="official-source_box_item_text-block__title" itemprop="name">
            МУГАДН
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OfficialSource'
}
</script>

<style lang="scss" scoped>
.official-source {
  color: #010B23;
  &__title {
    font-size: 24px;
    font-weight: 600;
    line-height: 32.4px;
    text-align: center;
    margin-bottom: 30px;
    color: #010B23;
    @media(max-width: 767px) {
      font-size: 22px;
      font-weight: 500;
      line-height: 29.7px;
      text-align: left;
      margin-bottom: 18px;
    }
  }
  &_box {
    display: flex;
    gap: 30px;
    @media(max-width: 991px) {
      flex-direction: column;
      gap: 40px;
    }
    @media(max-width: 767px) {
      gap: 16px;
    }
    &_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0px 4.38px 4.63px 0px #0061D906, 0px 20px 13px 0px #0061D909, 0px 50.63px 34.88px 0px #0061D90C, 0px 100px 80px 0px #0061D912;
      background-color: white;
      width: 100%;
      @media(max-width: 991px) {
        flex-direction: row;
        gap: 10px;
      }
      @media(max-width: 767px) {
        padding: 18px;
      }
      &__image {
        width: 100px;
        height: 100px;
        @media(max-width: 767px) {
          width: 100%;
          height: 100%;
          max-width: 50px;
        }
      }
      &_text-block {
        display: flex;
        align-items: center;
        height: 100%;
        &__title {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          @media(max-width: 767px) {
            text-align: left;
          }
        }
      }
    }
  }
}
</style>
