
import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class extends Vue {
  scrollToForm () {
    // const element = document.getElementById('form-for-accruals')
    // if (element) {
    //   element.scrollIntoView({ behavior: 'smooth', block: 'end' })
    // }
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
}
