
import { Component, Vue, Prop, Watch, Ref } from 'nuxt-property-decorator'
import LazyHydrate from 'vue-lazy-hydration'
import { Events } from '@pp-frontend/shared-packages/types/enum/Events'
import DocWidgetList from '@pp-frontend/shared-packages/components/Doc/WidgetList/DocWidgetList.vue'
import SearchFormCtc from '~/components/search-form/SearchFormCtc.vue'
import { ErrorValidation } from '~/types/ErrorValidation'
import SearchFormGrz from '~/components/search-form/SearchFormGrz.vue'

@Component({
  components: {
  SearchFormGrz,
  LazyHydrate,
  SearchFormCtc,
  SearchFormVu: () => import('~/components/search-form/SearchFormVu.vue'),
  SearchFormUin: () => import('~/components/search-form/SearchFormUin.vue'),
  DocWidgetList
  }
  })
export default class extends Vue {
  @Prop() value!: string
  @Prop() isIframe!: boolean
  @Prop() leadSourceId!: string
  @Ref() searchBlock!: HTMLElement

  tabIndex: number = 1
  dataErrorValidations: any = {}
  errorDocVal: string = ''
  allowServices = this.$allowServices()
  isOpen: boolean = false
  selectedTab: any = [{
    name: 'Проверка по гос. номеру'
  },
  {
    name: 'Проверка по автомобилю'
  },
  {
    name: 'Проверка по водителю'
  },
  {
    name: 'Оплата по постановлению'
  }
  ]

  tab: string = 'Проверка по гос. номеру'

  toggleDropdown () {
    this.isOpen = !this.isOpen
  }

  selectItem (tab: string, key: number) {
    this.isOpen = false
    this.tab = this.selectedTab[this.tabIndex - 1].name
    this.tabIndex = key + 1
  }

  get SelectedItem () {
    this.tab = this.selectedTab[this.tabIndex - 1].name
    return this.tab
  }

  @Watch('value', { deep: true })
  onChangeValue () {
    this.tabIndex = 1
  }

  @Watch('allowServices', { deep: true })
  allowServicesHandler () {
    if (!this.allowServices.ctc_by_grz && this.tabIndex === 1) {
      this.tabIndex = 2
    }
    return this.allowServices
  }

  mounted () {
    this.$eventBus.$on(Events.DATA_ERROR_FROM_VALIDATION, (error) => {
      this.dataErrorValidations = error
      this.errorDocVal = this.dataErrorValidations.error.response.data.error.document_value[0]
    })

    if (!this.$allowServices().ctc_by_grz) {
      this.tabIndex = 2
    }
    const newTab = parseInt(this.$route.hash.slice(1), 10)
    if (!isNaN(newTab)) {
      this.tabIndex = newTab
    }
  }

  @Watch('$route.hash')
  routeChange () {
    const newTab = parseInt(this.$route.hash.slice(1), 10)
    if (!isNaN(newTab)) {
      this.tabIndex = newTab
      this.$nextTick(() => {
        this.searchBlock.scrollIntoView({ behavior: 'smooth' })
      })
    }
  }

  getErrorFormValidations (type: string): {} | ErrorValidation {
    if (process.client && this.errorDocVal) {
      return type === this.dataErrorValidations.payerData[0].document_type
        ? {
            isError: true,
            msgError: this.errorDocVal
          }
        : {
            isError: false,
            msgError: ''
          }
    }

    return {
      isError: false,
      msgError: ''
    }
  }
}
