import { render, staticRenderFns } from "./Reviews.vue?vue&type=template&id=6ffe1a80&scoped=true"
import script from "./Reviews.vue?vue&type=script&lang=ts"
export * from "./Reviews.vue?vue&type=script&lang=ts"
import style0 from "./Reviews.vue?vue&type=style&index=0&id=6ffe1a80&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ffe1a80",
  null
  
)

export default component.exports