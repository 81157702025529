
import { Component, Vue } from 'nuxt-property-decorator'
import { Review } from '~/types/Review'
import { STUB_REVIEWS } from '~/stubs/reviews'

@Component({
  name: 'ExampleInfo',
  components: {
  FullScreenImage: () => import('~/components/FullScreenImage.vue')
  }
  })
export default class extends Vue {
  showFullScreen: number = 0;
  reviewsArr: Review[] = []
  reviewsIcon: number[] = [0, 1, 1, 0, 0, 0]

  async fetch () {
    await this.updateReviewsArr()
  }

  async updateReviewsArr () {
    try {
      const response = await this.$axios.get(
        process.env.API_ENDPOINT + `comments?page=0&page_size=3&client_app_id=${process.env.APP_ID}`,
        // @ts-ignore
        { requireToken: false }
      )
      this.reviewsArr = response.data.data
    } catch (err) {
      // @ts-ignore
      this.reviewsArr = STUB_REVIEWS
      this.$sentry.captureException(err)
    }
  }
}
