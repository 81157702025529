
import { Component, Vue, Prop } from 'nuxt-property-decorator'
import GibddForm from './GibddForm.vue'

@Component({
  components: {
  GibddForm
  }
  })
export default class extends Vue {
  @Prop() subTitle!: string
  @Prop() title!: string
  @Prop() canBack!: boolean
  @Prop({
    type: Boolean,
    default: true
  }) showTitle!: boolean
}
