
import { TheMask } from 'vue-the-mask'
import { Component, Prop, Ref } from 'nuxt-property-decorator'
import { FieldsId } from '@pp-frontend/shared-packages/types/enum/FieldsId'
import DocCtc from '@pp-frontend/shared-packages/classes/doc-value/DocCtc'
import SearchFormAbstract from '@pp-frontend/shared-packages/classes/search-form/SearchFormAbstract'
import LicencePlateInput from '../LicencePlateInput.vue'
import InfoBtn from './InfoBtn.vue'
import SearchFormFooter from '~/components/search-form/SearchFormFooter.vue'
import { ErrorValidation } from '~/types/ErrorValidation'
import SearchEmailField from '~/components/search/SearchEmailField.vue'
import SearchFormOfertaText from '~/components/search/SearchFormOfertaText.vue'

@Component({
  components: {
  SearchFormOfertaText,
  SearchEmailField,
  SearchFormFooter,
  TheMask,
  InfoBtn,
  LicencePlateInput
  }
  })
export default class extends SearchFormAbstract {
  @Prop({
    type: Object,
    default: () => ({
      isError: false,
      msgError: ''
    })
  }) dataErrorFormValidations!: ErrorValidation

  @Ref() ctcField!: HTMLInputElement

  USER_EMAIL_REQUIRED = true
  formData = new DocCtc()
  fieldId = FieldsId.CTC
  showTooltip = false
  plateNumPatternWithNums = /[0-9]|[АВЕКМНОРСТУХавекмнорстухABEKMHOPCTYXabekmhopctyx]/

  maskTokens = {
    '#': { pattern: /\d/ },
    F: {
      pattern: /[0-9АВЕКМНОРСТУХавекмнорстухABEKMHOPCTYXabekmhopctyx]/,
      transform (v: string) {
        const engLetters = 'ABEKMHOPCTYXabekmhopctyx'
        const translations = {
          A: 'А',
          B: 'В',
          E: 'Е',
          K: 'К',
          M: 'М',
          H: 'Н',
          O: 'О',
          P: 'Р',
          C: 'С',
          T: 'Т',
          Y: 'У',
          X: 'Х'
        }
        if (engLetters.includes(v)) {
          return translations[v.toLocaleUpperCase()]
        }
        return v.toLocaleUpperCase()
      }
    }
  }

  handleMaskKeydown (e: KeyboardEvent) {
    const currentKey = e.key
    if (!currentKey || currentKey === 'Backspace') {
      return
    }
    if (!currentKey.match(this.plateNumPatternWithNums)) {
      this.showTooltip = true
    }
    /**
     * Обработка 4 символа в ву
     * если 3 буква, 4 тоже должен быть буквой
     * если 3 число, 4 тоже должен быть числом
     * иначе останавливаем обработку эвента
     */
    if (this.formData.document_value.length === 3) {
      const getKeyType = (v: string) => isNaN(parseInt(v)) ? 'string' : 'number'
      if (getKeyType(this.formData.document_value[2]) !== getKeyType(currentKey)) {
        this.showTooltip = true
        e.preventDefault()
      }
    }
  }

  onInputLicence (e: any) {
    (this.$v.formData as any).additional_fields.licence_plate.$reset()
    if (e.length === 9 && this.$route.name !== 'ctc-not-found') {
      this.$nextTick(() => (this.ctcField as any).$el.focus())
    }
  }

  errorClientValid () {
    this.$v.$touch()
  }

  beforeMount () {
    this.formData.additional_fields.licence_plate = this.$route.query.licence_plate as string | undefined
  }

  getType (): string {
    return 'gibdd'
  }
}
