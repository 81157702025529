
export default {

  mounted () {
    const scrollToTopBtn = document.getElementById('scrollToTopBtn')

    window.onscroll = function () {
      if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
        // @ts-ignore
        scrollToTopBtn.style.display = 'block'
      } else {
        // @ts-ignore
        scrollToTopBtn.style.display = 'none'
      }
    }
  },

  methods: {
    scrollToTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
