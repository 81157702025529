import { render, staticRenderFns } from "./HowToUseForm.vue?vue&type=template&id=7c97ef41&scoped=true"
import script from "./HowToUseForm.vue?vue&type=script&lang=ts"
export * from "./HowToUseForm.vue?vue&type=script&lang=ts"
import style0 from "./HowToUseForm.vue?vue&type=style&index=0&id=7c97ef41&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c97ef41",
  null
  
)

export default component.exports